<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="USA DDT Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="USA DDT Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/USADDTDetailed-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/USADDTDetailed-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the USA DDT test is to determine whether
              igniting a substance under confinement will result in a
              deflagration to detonation (DDT) transition.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Confining medium (steel pipe with cap and witness plate)
                    </td>
                    <td>
                      Confined vessel to facilitate reaction propagation where
                      the witness plate is evidence of sample reactivity should
                      the material be capable of sustaining the reaction.
                    </td>
                    <td></td>
                    <td>
                      Pipe is 457 mm long of “3 inch schedule 80” carbon (A53
                      Grade B) with ID of 74 mm and wall thickness of 7.6 mm
                      capped at one end with “3000” pound forged steel cap.
                    </td>
                  </tr>
                  <tr>
                    <td>Ignition stimulus (igniter size and type)</td>
                    <td>
                      Ensure a sufficient amount of the material is ignited
                    </td>
                    <td></td>
                    <td>
                      An igniter with 5.0 grams of black powder (100% passed
                      through No. 20 sieve, 0.84 mm, and 100% retained by No 50
                      sieve, 0.297 mm). The igniter is located in the center of
                      the pipe. The igniter assembly consists of a cylindrical
                      container 21 mm in diameter which is made from 0.54 mm
                      thick cellulose acetate which is held together by two
                      layers of nylon filament reinforced cellulose acetate
                      tape. The length of the igniter capsule is approximately
                      1.6 cm for the 5 g igniter. The igniter capsule contains a
                      small loop formed from a 25 mm length of nickel-chromium
                      alloy resistance wire 0.30 mm in diameter having a
                      resistance of 0.343 ohms. This loop is attached to two
                      insulated copper lead wires. These lead wires are feed
                      through small holes in the wall of the pipe and are sealed
                      with epoxy resin.
                    </td>
                  </tr>
                  <tr>
                    <td>Witness plate specifications</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>
                      A 13 cm square, 8 mm thick mild steel witness plate is
                      welded to the other end of the pipe.
                    </td>
                  </tr>
                  <tr>
                    <td>Sample mass and configuration</td>
                    <td>
                      Adequate mass to determine the likelihood of a
                      deflagration to detonation transition
                    </td>
                    <td></td>
                    <td>
                      Sample is loaded to height of 23 cm after which the
                      igniter is placed in the pipe and the rest of the sample
                      loaded. The sample density should be close to the shipping
                      density.
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Adequately determine the reactivity result (DDT) with the
                      fewest number of trials
                    </td>
                    <td></td>
                    <td>3 trials</td>
                  </tr>
                  <tr>
                    <td>Pipe position</td>
                    <td>
                      Allow the weight of the substance to provide additional
                      confinement
                    </td>
                    <td></td>
                    <td>The tube is fired in a vertical position</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Hole is punched [subjective] through the witness plate.
                      [Not Class 1.5]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Photo of No Hole Punched Through Witness Plate (-)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/nogo-usaddt-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/nogo-usaddt-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of No Hole Punched Through Witness Plate (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88931829?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88931829"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
